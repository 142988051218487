<template>
    <div class="container">
        <FilterDashboard @filter="onFilter" @filterStart="onFilterStart" @filterEnd="onFilterEnd"
            @filterStatus="onFilterStatus" @filterNivel="onFilterNivel" :showNivel="true" :isClosingDate="true" />

        <div style="text-align:right" class="chart">
            <div class="row">
                <div class="col-md-6">
                    <h5>Vacantes Cerradas</h5>
                    <h6>Total de vacantes: {{ total }}</h6>
                    <h6>Promedio de días hábiles: {{ promedio }}</h6>
                </div>
                <div class="col-md-3">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button :class="{ active: activeTerna }" type="button" class="btn btn-primary"
                            @click="changeChart('terna')">Terna</button>
                        <button :class="{ active: activeFin }" type="button" class="btn btn-primary"
                            @click="changeChart('fin')">Fecha Fin</button>
                        <button :class="{ active: activeAdmision }" type="button" class="btn btn-primary"
                            @click="changeChart('admision')">Admisión</button>

                    </div>
                </div>
            </div>

        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <div v-if="activeFin">
                <apexchart ref="chart" width="700" type="bar" :options="{ ...options }" :series="series">
                </apexchart>
            </div>
            <div v-if="activeAdmision">
                <apexchart ref="chart" width="700" type="bar" :options="{ ...options }" :series="series">
                </apexchart>
            </div>
            <div v-if="activeTerna">
                <apexchart ref="chart" width="700" type="bar" :options="{ ...options }" :series="series">
                </apexchart>
            </div>
        </div>

    </div>
</template>

<script>

import CompanyService from "../../services/company.service";
import VueApexCharts from 'vue3-apexcharts';
import FilterDashboard from "../filter/FilterDashboard.vue";

export default ({
    name: 'vacantes-nivel',
    components: { apexchart: VueApexCharts, FilterDashboard },
    data() {
        return {
            activeFin: false,
            activeAdmision: false,
            activeTerna: true,
            clearFilter: false,
            total: 0,
            promedio: 0,
            options: {
                chart: {
                    id: 'vuechart-nivel',
                    type: 'bar'
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Días Hábiles'
                    }
                },
                fill: {
                    opacity: 1,
                    colors: ['#00BFFF', '#A9A9A9']
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '',
                        distributed: false,
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                colors: []
                            }],
                            backgroundBarColors: [],
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 0,
                        },
                        dataLabels: {
                            position: 'top',
                            maxItems: 10,
                            total: {
                                enabled: true,
                                formatter: undefined,
                                offsetX: 0,
                                offsetY: 0,
                                style: {
                                    color: '#373d3f',
                                    fontSize: '12px',
                                    fontFamily: undefined,
                                    fontWeight: 600
                                }
                            }
                        }
                    }
                }
            },
            series: [{
                name: 'vacante',
                data: []
            }],
            user: JSON.parse(localStorage.getItem("user")),
            niveles: [],
            filtered: {
                filterStart: "",
                filterEnd: "",
                filterStatus: "",
                filterNivel: "",
                userId: ""
            }
        }
    },
    mounted() {
        this.filtered.userId = this.user._id;
        //this.cargaDatosByUser(this.user);
    },
    methods: {
        async getVacantesByFechaFinAndUserId(userId) {
            try {
                const response = await CompanyService.getVacantesByFechaFinAndUserId(userId);

                const { data } = response.data;
                this.promedio = data[0].totalDaysAvg;
                this.total = data[0].totalVacantes;

                data[0].vacantes.map((item) => {
                    this.options.xaxis.categories.push(item.vacante);
                });

                data[0].vacantes.map((item) => {
                    this.series[0].data.push(item.totalDays);
                });
                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));

                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };


                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }

        },
        async getVacantesByFechaAdmisionAndUserId(userId) {
            try {
                const response = await CompanyService.getVacantesByFechaAdmisionAndUserId(userId);

                const { data } = response.data;
                this.promedio = data[0].totalDaysAvg;
                this.total = data[0].totalVacantes;

                data[0].vacantes.map((item) => {
                    this.options.xaxis.categories.push(item.vacante);
                });

                data[0].vacantes.map((item) => {
                    this.series[0].data.push(item.totalDays);
                });
                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));

                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };


                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }

        },
        async getVacantesByFechaTernaAndUserId(userId) {
            try {
                const response = await CompanyService.getVacantesByFechaTernaAndUserId(userId);

                const { data } = response.data;
                this.promedio = data[0].totalDaysAvg;
                this.total = data[0].totalVacantes;

                data[0].vacantes.map((item) => {
                    this.options.xaxis.categories.push(item.vacante);
                });

                data[0].vacantes.map((item) => {
                    this.series[0].data.push(item.totalDays);
                });
                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));

                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };


                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }

        },
        async cargaDatosByUser(user) {
            this.options.xaxis = { ... this.options.xaxis, categories: [] };
            this.series[0] = { ...this.series[0], data: [] };

            if (this.activeFin)
                this.getVacantesByFechaFinAndUserId(user._id);
            if (this.activeAdmision)
                this.getVacantesByFechaAdmisionAndUserId(user._id);
            if (this.activeTerna)
                this.getVacantesByFechaTernaAndUserId(user._id);
        },
        async cargaDatosByFilter() {
            this.options.xaxis = { ... this.options.xaxis, categories: [] };
            this.series[0] = { ...this.series[0], data: [] };

            if (this.activeFin)
                this.getVacantesByFechaFinFilter();
            if (this.activeAdmision)
                this.getVacantesByFechaAdmisionFilter();
            if (this.activeTerna)
                this.getVacantesByFechaTernaFilter();
        },
        async getVacantesByFechaFinFilter() {
            try {
                const response = await CompanyService.getVacantesByFechaFinFilter(this.filtered);

                const { data } = response.data;
                this.promedio = data[0].totalDaysAvg;
                this.total = data[0].totalVacantes;

                data[0].vacantes.map((item) => {
                    this.options.xaxis.categories.push(item.vacante);
                });

                data[0].vacantes.map((item) => {
                    this.series[0].data.push(item.totalDays);
                });
                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));

                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };


                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }

        },
        async getVacantesByFechaAdmisionFilter() {
            try {
                const response = await CompanyService.getVacantesByFechaAdmisionFilter(this.filtered);

                const { data } = response.data;
                this.promedio = data[0].totalDaysAvg;
                this.total = data[0].totalVacantes;

                data[0].vacantes.map((item) => {
                    this.options.xaxis.categories.push(item.vacante);
                });

                data[0].vacantes.map((item) => {
                    this.series[0].data.push(item.totalDays);
                });
                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));

                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };


                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }

        },
        async getVacantesByFechaTernaFilter() {
            try {
                const response = await CompanyService.getVacantesByFechaTernaFilter(this.filtered);

                const { data } = response.data;
                this.promedio = data[0].totalDaysAvg;
                this.total = data[0].totalVacantes;

                data[0].vacantes.map((item) => {
                    this.options.xaxis.categories.push(item.vacante);
                });

                data[0].vacantes.map((item) => {
                    this.series[0].data.push(item.totalDays);
                });
                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));

                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };


                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }

        },
        onFilter(filter) {
            this.filtered.filter = filter;
            this.cargaDatosByFilter();
        },
        onFilterStart(filter) {
            this.filtered.filterStart = filter;
        },
        onFilterEnd(filter) {
            this.filtered.filterEnd = filter;
            this.cargaDatosByFilter();
        },
        onFilterStatus(filter) {
            this.filtered.filterStatus = filter;
            this.cargaDatosByFilter();
        },
        onFilterNivel(filter) {
            this.filtered.filterNivel = filter;
            this.cargaDatosByFilter();
        },
        onClearFilter(filter) {
            if (filter) {
                this.cargaDatosByUser(this.user);
            }

        },
        changeChart(type) {
            const chartTypes = {
                fin: 'activeFin',
                admision: 'activeAdmision',
                terna: 'activeTerna'
            };

            this.activeFin = false;
            this.activeAdmision = false;
            this.activeTerna = false;

            if (chartTypes[type] !== undefined) {
                this[chartTypes[type]] = !this[chartTypes[type]];
            }

            this.options.xaxis = { ... this.options.xaxis, categories: [] };
            this.series[0] = { ...this.series[0], data: [] };

            if (this.filtered)
                this.cargaDatosByFilter();
            else
                this.cargaDatosByUser(this.user);

        },

    }
})
</script>

<style>
.chart {
    padding-top: 3%;
}
</style>
